window.$ = window.jQuery = require("jquery");

require("../sass/style.scss");

require('./vendor/slick.min');
// require('./vendor/jquery-equal-height.min.js');




(function ($) {
    $(document).ready(function () {
        //************************************
        // Parallax content
        //************************************


        let $slick_slider = $('.blog-posts-api');
        let settings_slider = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: false,
            arrows: false
        };
        slick_on_mobile( $slick_slider, settings_slider);


        $('.wrapper_content h3 a').equalHeight();

        $(window).on('load resize', function() {
            $('.wrapper_content h3 a').equalHeight();
        });

    });



// slick on mobile
    function slick_on_mobile(slider, settings){
        $(window).on('load resize', function() {
            if ($(window).width() > 768) {
                if (slider.hasClass('slick-initialized')) {
                    slider.slick('unslick');
                }
                return
            }
            if (!slider.hasClass('slick-initialized')) {
                return slider.slick(settings);
            }
        });
    }




})(jQuery);

(function ($) {
    $.fn.extend({
        size: function () {
            return $(this).length;
        }
    });

    $.fn.equalHeight = function() {
        var maxHeight = 0;
        return this.each(function(index, box) {
            var boxHeight = $(box).height();
            maxHeight = Math.max(maxHeight, boxHeight);
        }).parent().height(maxHeight);
    };
})(jQuery);